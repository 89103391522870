<template>
  <div>
    <div class="shopping-cart__table">
      <div class="shopping-cart-light">
        <div class="shopping-cart-row">
          <div class="cart-product">Product</div>
          <div class="cart-price">Price</div>
          <div class="cart-quantity">Quantity</div>
          <div class="cart-total">Total</div>
          <div class="cart-action"></div>
        </div>
      </div>
      <div class="shopping-cart-body">
        <template v-if="cart">
          <div class="shopping-cart-row" v-for="(item, index) in cart.items" :key="index">
            <div class="cart-product">
              <div class="ps-product--mini-cart">
                <router-link :to="{ name: 'product-view', params: { productId: item.product.id } }">
                  <img class="ps-product__thumbnail" :src="item.product.base_image.small_image_url" :alt="item.name" />
                </router-link>
                <div class="ps-product__content">
                  <h5>
                    <router-link
                      class="ps-product__name"
                      :to="{ name: 'product-view', params: { productId: item.product.id } }"
                    >
                      {{ item.name }}
                    </router-link>
                  </h5>

                  <p class="ps-product__unit limit_text">SKU: {{ item.sku }}</p>
                  <p class="ps-product__meta">
                    Price:
                    <span class="ps-product__price">{{ item.formated_price }} </span>
                    <label id="is_sale"> {{ item.product.formated_regular_price }}</label>
                  </p>

                  <div class="def-number-input number-input safari_only">
                    <button
                      class="minus"
                      onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                      @click="decreaseQuantity(item.id)"
                    >
                      <i class="icon-minus"></i>
                    </button>
                    <input class="quantity" min="0" name="quantity" v-model="item.quantity" type="number" />
                    <button
                      class="plus"
                      onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                      @click="increaseQuantity(item.id)"
                    >
                      <i class="icon-plus"></i>
                    </button>
                  </div>

                  <span class="ps-product__total">
                    Total: <span>{{ item.formated_base_total }} </span>
                  </span>
                </div>
                
                <div class="ps-product__remove">
                  <i class="icon-trash2" @click.prevent="removeItem(item.id)"></i>
                </div>
              </div>
            </div>

            <div class="cart-price">
              <span class="ps-product__price">{{ item.formated_price }}</span>
            </div>

            <div class="cart-quantity">
              <div class="def-number-input number-input safari_only">
                <button class="minus" @click="decreaseQuantity(item.id), onUpdateCart()">
                  <i class="icon-minus"></i>
                </button>
                <input class="quantity" min="0" name="quantity" v-model="item.quantity" type="number" />
                <button class="plus" @click="increaseQuantity(item.id), onUpdateCart()">
                  <i class="icon-plus"></i>
                </button>
              </div>
            </div>

            <div class="cart-total">
              <span class="ps-product__total">{{ item.formated_total }} </span>
            </div>

            <div class="cart-action">
              <a href="" @click.prevent="removeItem(item.id)"> <i class="icon-trash2"></i></a>
            </div>
          </div>
        </template>

        <template v-else>
          <p></p>
        </template>
      </div>
    </div>
    <div class="shopping-cart__step">
      <a class="clear-item" href="javascript:void(0);" @click="emptyCart()" v-if="!isEmpty">Clear all Item</a>
      <a class="button btn-back right" href="javascript:void(0);" @click.prevent="onUpdateCart" v-if="!isEmpty"
        ><i class="icon-sync"></i>{{ isLoading ? "Updating..." : "Update Cart" }}</a
      >
      <div class="contiune_shop">
        <a class="button left" href="#" @click.prevent="$router.push({ name: 'products' })"
          ><i class="icon-arrow-left"></i>Continue Shopping</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("cart", ["cart", "items", "isLoading", "isEmpty"]),
  },
  methods: {
    ...mapActions("cart", ["increaseQuantity", "decreaseQuantity", "removeItem", "updateCartItem", "emptyCart"]),
    onUpdateCart() {
      let qty = {};
      this.items.map((a) => {
        qty[a.id] = a.quantity;
      });
      this.updateCartItem({ qty: qty });
    },
  },
};
</script>

<style lang="scss" scoped>
.contiune_shop .button {
  background-color: #ff7200;
  color: #ffff;
  i {
    color: #ffff;
  }
}
.shopping-cart__step .btn-back {
  background-color: #ff7200;
  color: white;
  i {
    color: #ffff;
  }
}
#is_sale {
  text-decoration: line-through;
  color: #d92022;
  font-weight: bold;
}
.limit_text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
}
</style>
