<template>
  <div style="display: block;max-height: 350px;overflow-y: scroll;">
  <table id="cart" class="table table-condensed deskstop_view" v-if="!$isMobile()" >
    <thead>
      <tr>
        <th style="width: 50%" class="text-left">Product</th>
        <th style="width: 15%">Price</th>
        <th style="width: 15%" class="text-center">Quantity</th>
        <th style="width: 10%" class="text-right total_text">Total</th>
        <th style="width: 10%"></th>
      </tr>
    </thead>
    <tbody v-if="cart">
      <template v-if="!isEmpty">
        <tr v-for="(item, index) in cart.items" :key="index">
          <td data-th="Product">
            <div class="row">
              <div class="col-sm-3 hidden-xs image">
                <router-link :to="{ name: 'product-view', params: { productId: item.product.id } }">
                  <img
                    :src="item.product.base_image.small_image_url"
                    :alt="item.name"
                    class="img-responsive img_product"
                  />
                </router-link>
              </div>
              <div class="col-sm-9">
                <router-link :to="{ name: 'product-view', params: { productId: item.product.id } }">
                  <h4 class="nomargin">{{ item.name }}</h4>
                </router-link>
                <p class="limit_text">SKU: {{ item.sku }}</p>
              </div>
            </div>
          </td>
          <td data-th="Price text-right">
            {{ item.formated_price }}
            <div class="ps-product__is-sale">{{ discountPrice(item) }}</div>
          </td>
          <td data-th="Quantity">
            <div class="def-number-input number-input safari_only ">
              <button
                class="minus"
                onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                @click="decreaseQuantity(item.id), onUpdateCart()"
              >
                <i class="icon-minus"></i>
              </button>
              <input class="quantity" min="1" name="quantity" type="number" v-model="item.quantity" />
              <button
                class="plus"
                onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                @click="increaseQuantity(item.id), onUpdateCart()"
              >
                <i class="icon-plus"></i>
              </button>
            </div>
          </td>
          <td data-th="Total" class="text-right total_text">{{ item.formated_total }}</td>
          <td class="actions text-right" data-th="">
            <a class=" btn_delete btn-lg" @click.prevent="removeItem(item.id)">
              <i class="icon-trash2"></i>
            </a>
          </td>
        </tr>
       </template>
       <template v-else>
         <tr>
           <td colspan="4">
             Items not found
           </td>
         </tr>
       </template>
    </tbody>
  </table>
  </div>

   <table width="100%" style="margin-top:30px" v-if="!$isMobile()">
     <tfoot>
      <tr>
        <td>
          <a href="#" class="btn  btn-lg buttons_clr" @click.prevent="$router.push({ name: 'products' })"
            ><i class="icon-arrow-left"></i> Continue Shopping</a>
        </td>

        <td colspan="2">
          <a href="#" class="btn btn-lg " @click="emptyCart()" v-if="!isEmpty"><u>Clear All items</u></a>
        </td>
        <td>
          <a href="#" class="btn btn-lg btn-block update_cart buttons_clr" @click.prevent="onUpdateCart" v-if="!isEmpty"
            ><i class="icon-sync"></i> {{ isLoading ? "Updating..." : "Update Cart" }}</a
          >
        </td>
      </tr>
    </tfoot>
  </table>
  <ShoppingCartTableMobile v-if="$isMobile()" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ShoppingCartTableMobile from "./ShoppingCartTableMobile";
export default {
  components: {
    ShoppingCartTableMobile,
  },
  computed: {
    ...mapGetters("cart", ["cart", "items", "isLoading", "isEmpty"]),
  },
  methods: {
    ...mapActions("cart", ["increaseQuantity", "decreaseQuantity", "removeItem", "updateCartItem", "emptyCart"]),
    onUpdateCart() {
      let qty = {};
      this.items.map((a) => {
        qty[a.id] = a.quantity;
      });
      this.updateCartItem({ qty: qty });
    },

    discountPrice(item) {
      if(item.product.formated_regular_price != undefined) {
        return item.product.formated_regular_price;
      }
      else if (item.price != item.product.price) {
        return item.product.formated_price;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle;
}
.ps-product__is-sale {
  text-decoration: line-through;
  color: #d92022;
}
th {
  font-weight: bold;
}
.btn_delete {
  width: 100% !important;
  font-size: 18px;
}
.btn_delete:hover {
  color: #d92022;
  cursor: pointer;
}
.buttons_clr {
  background-color: #ff7200;
  color: #ffff;
  i {
    color: #ffff;
  }
}
.limit_text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
}
@media screen and (max-width: 1440px) {
  .mobile_view {
    visibility: hidden;
    display: none;
  }
  .deskstop_view {
    visibility: visible;
  }
}
@media screen and (max-width: 414px) {
  .deskstop_view {
    display: none;
  }
  .mobile_view {
    visibility: visible;
    display: block;
  }
}
</style>
