<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active"><a href="#">Home</a></li>
          <li class="active"><a href="#">Shop</a></li>
          <li><a href="javascript:void(0);">Shopping Cart</a></li>
        </ul>
      </div>
    </div>
    <section class="section--shopping-cart">
      <div class="shopping-container">
        <h2 class="page__title">Shopping Cart</h2>
        <div class="shopping-cart__content">
          <div class="row">
            <div class="col-12 col-lg-8">
              <div class="shopping-cart__products">
                <ShoppingCartTable />
              </div>
            </div>
            
            <div class="col-12 col-lg-4">
              <div class="shopping-cart__right" v-if="cart.items_count">
                <div class="shopping-cart__total">
                  <p class="shopping-cart__subtotal" v-if="false">
                    <span>Unit Price Total</span><span class="price">{{ $formatNumber(unitPriceTotal, cart.cart_currency_code) }}</span>
                  </p>
                   <p class="shopping-cart__subtotal">
                    <span>Subtotal</span><span class="price">{{ cart.formated_sub_total }}</span>
                  </p>
                  <hr>
                  <p class="shopping-cart__subtotal">
                    <span>Product Discount</span><span class="price">-{{ $formatNumber(productDiscount, cart.cart_currency_code) }}</span>
                  </p>
                  <p class="shopping-cart__subtotal" v-if="!!cart.discount">
                    <span>Cart Discount</span><span class="price">-{{ cart.formated_discount }}</span>
                  </p>
                  <p class="shopping-cart__subtotal">
                    <span><b>TOTAL</b></span
                    ><span class="price-total">{{ cart.formated_base_discounted_sub_total }}</span>
                  </p>
                </div>
                <router-link class="btn shopping-cart__checkout" :to="{ name: 'checkout' }">
                  Proceed to Checkout</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import ShoppingCartTable from "./Components/ShoppingCartTable";

export default {
  name: "ShoppingCart",

  components: {
    ShoppingCartTable,
  },

  computed:{
    ...mapGetters('cart', ['cart', 'isEmpty', 'unitPriceTotal','productDiscount'])
  }
};
</script>
